import '../styles/lexical-font-size.css';

import { useState, useEffect } from 'react';

import { MAX_ALLOWED_FONT_SIZE, MIN_ALLOWED_FONT_SIZE, UpdateFontSizeType } from '../constants/LexicalConstants';
import LexicalUtils from '../utils/LexicalUtils';

export function parseAllowedFontSize(input) {
    const match = input.match(/^(\d+(?:\.\d+)?)px$/);
    if (match) {
        const n = Number(match[1]);
        if (n >= MIN_ALLOWED_FONT_SIZE && n <= MAX_ALLOWED_FONT_SIZE) {
            return input;
        }
    }
    return '';
}

export default function FontSize({ selectionFontSize, disabled, editor}) {
    const [inputValue, setInputValue] = useState(selectionFontSize);
    const [inputChangeFlag, setInputChangeFlag] = useState(false);

    const handleKeyPress = (e) => {
        const inputValueNumber = Number(inputValue);

        if (e.key === 'Tab') {
            return;
        }
        if (['e', 'E', '+', '-'].includes(e.key) || isNaN(inputValueNumber)) {
            e.preventDefault();
            setInputValue('');
            return;
        }
        setInputChangeFlag(true);
        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault();
            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const handleInputBlur = () => {
        if (inputValue !== '' && inputChangeFlag) {
            const inputValueNumber = Number(inputValue);
            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const updateFontSizeByInputValue = (inputValueNumber) => {
        let updatedFontSize = inputValueNumber;
        if (inputValueNumber > MAX_ALLOWED_FONT_SIZE) {
            updatedFontSize = MAX_ALLOWED_FONT_SIZE;
        } else if (inputValueNumber < MIN_ALLOWED_FONT_SIZE) {
            updatedFontSize = MIN_ALLOWED_FONT_SIZE;
        }

        setInputValue(String(updatedFontSize));
        LexicalUtils.updateFontSizeInSelection(editor, String(updatedFontSize) + 'px', null);
        setInputChangeFlag(false);
    };

    useEffect(() => {
        setInputValue(selectionFontSize);
    }, [selectionFontSize]);

    return (
        <>
            <button
                type="button"
                disabled={
                    disabled ||
                    (selectionFontSize !== '' &&
                        Number(inputValue) <= MIN_ALLOWED_FONT_SIZE)
                }
                onClick={() =>
                    LexicalUtils.updateFontSize(editor, UpdateFontSizeType.decrement, inputValue)
                }
                className="toolbar-item font-decrement"
                aria-label="Decrease font size"
                title={`Decrease font size (${LexicalUtils.SHORTCUTS.DECREASE_FONT_SIZE})`}
            >
                <i className="format minus-icon"/>
            </button>

            <input
                type="number"
                title="Font size"
                value={inputValue}
                disabled={disabled}
                className="toolbar-item font-size-input"
                min={MIN_ALLOWED_FONT_SIZE}
                max={MAX_ALLOWED_FONT_SIZE}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
                onBlur={handleInputBlur}
            />

            <button
                type="button"
                disabled={
                    disabled ||
                    (selectionFontSize !== '' &&
                        Number(inputValue) >= MAX_ALLOWED_FONT_SIZE)
                }
                onClick={() =>
                    LexicalUtils.updateFontSize(editor, UpdateFontSizeType.increment, inputValue)
                }
                className="toolbar-item font-increment"
                aria-label="Increase font size"
                title={`Increase font size (${LexicalUtils.SHORTCUTS.INCREASE_FONT_SIZE})`}
            >
                <i className="format add-icon"/>
            </button>
        </>
    );
}
