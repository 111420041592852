import { useId, useRef, useState, useEffect } from 'react';

import * as DashboardConstants from '../constants/DashboardConstants';
import * as UiConstants from '../constants/UiConstants';
import * as LexicalConstants from '../constants/LexicalConstants';
import UiUtils from '../utils/UiUtils';

import DashboardMoreMenu from './DashboardMoreMenu';
import LexicalRichTextEditor from './LexicalRichTextEditor';

export default function DashboardCanvasLexicalCard({ index, id, row, col, width, height, details, mode, onCanvasElementAction }) {
    const newId = useId();
    const cardId = id ? id : newId;
    const offset = index ? index + 1 : 1;

    const containerRef = useRef(null);

    let rowColClass = "";

    let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
    let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
    let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
    let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

    switch (rowStart) {
        case 1:
            rowColClass = "row-start-1";
            break;
        case 2:
            rowColClass = "row-start-2";
            break;
        case 3:
            rowColClass = "row-start-3";
            break;
        default:
            break;
    }

    switch (colStart) {
        case 1:
            rowColClass = `${rowColClass} col-start-1`;
            break;
        case 2:
            rowColClass = `${rowColClass} col-start-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-start-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-start-4`;
            break;
        default:
            break;
    }

    switch (rowEnd) {
        case 2:
            rowColClass = `${rowColClass} row-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} row-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} row-end-4`;
            break;
        default:
            break;
    }

    switch (colEnd) {
        case 2:
            rowColClass = `${rowColClass} col-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-end-4`;
            break;
        case 5:
            rowColClass = `${rowColClass} col-end-5`;
            break;
        default:
            break;
    }

    let outerClass = `flex h-full flex-col items-stretch gap-6 p-6 overflow-hidden ${rowColClass}`;
    outerClass = `${outerClass} ${mode === DashboardConstants.CanvasMode.EDIT ? "rounded-lg bg-grey04 border border-grey03" : "bg-white"}`;

    const onMoreMenuClick = (event) => {
        if (onCanvasElementAction) {
            switch (event.action) {
                case DashboardConstants.ActionType.ELEMENT_MENU_EDIT:
                    onCanvasElementAction({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_EDIT,
                        args: {
                            id: event.args.id,
                            row: row,
                            col: col,
                            width: width,
                            height: height
                        }
                    });
                    break;
                case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
                    if (window.confirm("Are you sure you want to delete this story/commentary element?")) {
                        onCanvasElementAction({
                            action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                            args: {
                                id: event.args.id,
                                row: row,
                                col: col
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /* Text Change Handling */

    const [text, setText] = useState(details.text);

    const onChange = (content) => {
        setText(UiUtils.sanitiseHtml(content));
    }

    const onBlur = (content) => {
        if (onCanvasElementAction) {
            onCanvasElementAction({
                action: DashboardConstants.ActionType.TEXT_UPDATED,
                args: {
                    id: details.id,
                    text: UiUtils.sanitiseHtml(content)
                }
            });
        }
    }

    /* Timed Update Checks */

    const offsetMs = offset * LexicalConstants.TEXT_OFFSET_INTERVAL;

    const [timeoutStarted, setTimeoutStarted] = useState(false);
    const [intervalStarted, setIntervalStarted] = useState(false);

    return (
        <div key={`canvas-lexical-card-${cardId}-${row}-${col}`} className={outerClass}>
            {details.title && (
                <div className="flex items-center justify-between grow-0">
                    <h6 className="font-vg-regular text-grey text-base leading-130 grow">{details.title}</h6>
                    {mode === DashboardConstants.CanvasMode.EDIT && (
                        <DashboardMoreMenu
                            items={UiConstants.DASH_MORE_MENU_ITEMS}
                            params={{id: details.id}}
                            onMenuClick={onMoreMenuClick}
                        />
                    )}
                </div>
            )}
            {mode === DashboardConstants.CanvasMode.EDIT ? (
                <>
                    {details.title ? (
                        <div className="grow" ref={containerRef}>
                            <LexicalRichTextEditor
                                content={text}
                                heightRef={containerRef}
                                toolbarSize={width}
                                onChange={onChange}
                                onBlur={onBlur}
                                showMoreMenu={false}
                            />
                        </div>
                    ) : (
                        <div className="grow" ref={containerRef}>
                            <LexicalRichTextEditor
                                content={text}
                                heightRef={containerRef}
                                toolbarSize={width}
                                onChange={onChange}
                                onBlur={onBlur}
                                showMoreMenu={true}
                                moreMenuId={details.id}
                                onMoreMenuClick={onMoreMenuClick}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className="font-vg-book grow overflow-hidden" dangerouslySetInnerHTML={{__html: text}}></div>
            )}
        </div>
    );
}