import { Fragment } from 'react';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';

import * as UiConstants from '../constants/UiConstants';
import * as DashboardConstants from '../constants/DashboardConstants';

export default function DashboardMoreMenu({ items, params, className, onMenuClick }) {
    const getIcon = (action, active) => {
        switch (action) {
            case UiConstants.ActionKeys.EDIT:
                return (
                    <PencilSquareIcon className={`w-5 h-5 ${active ? "text-black" : "text-grey"}`}/>
                );
            case UiConstants.ActionKeys.DELETE:
                return (
                    <TrashIcon className="w-5 h-5 text-red"/>
                );
            default:
                return (
                    <></>
                );
        }
    }

    const onItemClick = (action) => {
        if (onMenuClick) {
            switch (action) {
                case UiConstants.ActionKeys.EDIT:
                    onMenuClick({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_EDIT,
                        args: { id: params.id }
                    });
                    break;
                case UiConstants.ActionKeys.DELETE:
                    onMenuClick({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                        args: { id: params.id }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <Menu as="div" className={`relative inline-block text-left${className ? ` ${className}` : ""}`}>
            <MenuButton>
                <EllipsisVerticalIcon className="w-5 h-5 text-grey"/>
            </MenuButton>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className="absolute right-0 mt-2 w-44 shadow-dash-more-menu bg-white rounded-lg z-[99999]">
                    {items.map((item, index) => (
                        <MenuItem key={`more-menu-wrapper-${params.id}-${index}`} >
                            {({active}) => (
                                <button 
                                    key={`more-menu-${params.id}-${index}`} 
                                    className={`w-full flex p-4 items-center justify-start gap-2 ${active ? "bg-blue03" : ""}`}
                                    onClick={(_) => onItemClick(item.action)}
                                >
                                    {getIcon(item.action, active)}
                                    <span className={`font-vg-book text-base ${active ? "text-black" : "text-grey"}`}>{item.label}</span>
                                </button>
                            )}
                        </MenuItem>
                    ))}
                </MenuItems>
            </Transition>        
        </Menu>
    );
}