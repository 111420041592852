import { $getSelection } from 'lexical';
import { $isAtNodeEnd, $patchStyleText } from '@lexical/selection';

import * as LexicalConstants from '../constants/LexicalConstants';

let LexicalUtils = {};

LexicalUtils.getSelectedNode = (selection) => {
   const anchor = selection.anchor;
   const focus = selection.focus;
   const anchorNode = selection.anchor.getNode();
   const focusNode = selection.focus.getNode();
   if (anchorNode === focusNode) {
      return anchorNode;
   }
   const isBackward = selection.isBackward();
   if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
   } else {
      return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
   }
};

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

LexicalUtils.setFloatingElemPositionForLinkEditor = (targetRect, floatingElem, anchorElem, verticalGap = VERTICAL_GAP, horizontalOffset = HORIZONTAL_OFFSET) => {
   const scrollerElem = anchorElem.parentElement;

   if (targetRect === null || !scrollerElem) {
      floatingElem.style.opacity = '0';
      floatingElem.style.transform = 'translate(-10000px, -10000px)';
      return;
   }

   const floatingElemRect = floatingElem.getBoundingClientRect();
   const anchorElementRect = anchorElem.getBoundingClientRect();
   const editorScrollerRect = scrollerElem.getBoundingClientRect();

   let top = targetRect.top - verticalGap;
   let left = targetRect.left - horizontalOffset;

   if (top < editorScrollerRect.top) {
      top += floatingElemRect.height + targetRect.height + verticalGap * 2;
   }

   if (left + floatingElemRect.width > editorScrollerRect.right) {
      left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
   }

   top -= anchorElementRect.top;
   left -= anchorElementRect.left;

   floatingElem.style.opacity = '1';
   floatingElem.style.transform = `translate(${left}px, ${top}px)`;
};

const SUPPORTED_URL_PROTOCOLS = new Set([
   'http:',
   'https:',
   'mailto:',
   'sms:',
   'tel:',
]);


LexicalUtils.sanitizeUrl = (url) => {
   try {
      const parsedUrl = new URL(url);
      // eslint-disable-next-line no-script-url
      if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
         return 'about:blank';
      }
   } catch {
      return url;
   }
   return url;
};

// Source: https://stackoverflow.com/a/8234912/2013580
const urlRegExp = new RegExp(
   /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);

LexicalUtils.validateUrl = (url) => {
   // TODO Fix UI for link insertion; it should never default to an invalid URL such as https://.
   // Maybe show a dialog where they user can type the URL before inserting it.
   return url === 'https://' || urlRegExp.test(url);
}  

const CAN_USE_DOM =
   typeof window !== 'undefined' &&
   typeof window.document !== 'undefined' &&
   typeof window.document.createElement !== 'undefined';

const IS_APPLE = CAN_USE_DOM && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

LexicalUtils.CAN_USE_DOM = CAN_USE_DOM;
LexicalUtils.IS_APPLE = IS_APPLE;

LexicalUtils.SHORTCUTS = Object.freeze({
    // (Ctrl|⌘) + (Alt|Option) + <key> shortcuts
    NORMAL: IS_APPLE ? '⌘+Opt+0' : 'Ctrl+Alt+0',
    HEADING1: IS_APPLE ? '⌘+Opt+1' : 'Ctrl+Alt+1',
    HEADING2: IS_APPLE ? '⌘+Opt+2' : 'Ctrl+Alt+2',
    HEADING3: IS_APPLE ? '⌘+Opt+3' : 'Ctrl+Alt+3',
    BULLET_LIST: IS_APPLE ? '⌘+Opt+4' : 'Ctrl+Alt+4',
    NUMBERED_LIST: IS_APPLE ? '⌘+Opt+5' : 'Ctrl+Alt+5',
    CHECK_LIST: IS_APPLE ? '⌘+Opt+6' : 'Ctrl+Alt+6',
    CODE_BLOCK: IS_APPLE ? '⌘+Opt+C' : 'Ctrl+Alt+C',
    QUOTE: IS_APPLE ? '⌘+Opt+Q' : 'Ctrl+Alt+Q',

    // (Ctrl|⌘) + Shift + <key> shortcuts
    INCREASE_FONT_SIZE: IS_APPLE ? '⌘+Shift+.' : 'Ctrl+Shift+.',
    DECREASE_FONT_SIZE: IS_APPLE ? '⌘+Shift+,' : 'Ctrl+Shift+,',
    INSERT_CODE_BLOCK: IS_APPLE ? '⌘+Shift+C' : 'Ctrl+Shift+C',
    STRIKETHROUGH: IS_APPLE ? '⌘+Shift+S' : 'Ctrl+Shift+S',
    LOWERCASE: IS_APPLE ? '⌘+Shift+1' : 'Ctrl+Shift+1',
    UPPERCASE: IS_APPLE ? '⌘+Shift+2' : 'Ctrl+Shift+2',
    CAPITALIZE: IS_APPLE ? '⌘+Shift+3' : 'Ctrl+Shift+3',
    CENTER_ALIGN: IS_APPLE ? '⌘+Shift+E' : 'Ctrl+Shift+E',
    JUSTIFY_ALIGN: IS_APPLE ? '⌘+Shift+J' : 'Ctrl+Shift+J',
    LEFT_ALIGN: IS_APPLE ? '⌘+Shift+L' : 'Ctrl+Shift+L',
    RIGHT_ALIGN: IS_APPLE ? '⌘+Shift+R' : 'Ctrl+Shift+R',

    // (Ctrl|⌘) + <key> shortcuts
    SUBSCRIPT: IS_APPLE ? '⌘+,' : 'Ctrl+,',
    SUPERSCRIPT: IS_APPLE ? '⌘+.' : 'Ctrl+.',
    INDENT: IS_APPLE ? '⌘+]' : 'Ctrl+]',
    OUTDENT: IS_APPLE ? '⌘+[' : 'Ctrl+[',
    CLEAR_FORMATTING: IS_APPLE ? '⌘+\\' : 'Ctrl+\\',
    REDO: IS_APPLE ? '⌘+Shift+Z' : 'Ctrl+Y',
    UNDO: IS_APPLE ? '⌘+Z' : 'Ctrl+Z',
    BOLD: IS_APPLE ? '⌘+B' : 'Ctrl+B',
    ITALIC: IS_APPLE ? '⌘+I' : 'Ctrl+I',
    UNDERLINE: IS_APPLE ? '⌘+U' : 'Ctrl+U',
    INSERT_LINK: IS_APPLE ? '⌘+K' : 'Ctrl+K',
});

const calculateNextFontSize = (currentFontSize, updateType) => {
    if (!updateType) {
        return currentFontSize;
    }
  
    let updatedFontSize = currentFontSize;
    switch (updateType) {
        case LexicalConstants.UpdateFontSizeType.decrement:
            switch (true) {
            case currentFontSize > LexicalConstants.MAX_ALLOWED_FONT_SIZE:
                updatedFontSize = LexicalConstants.MAX_ALLOWED_FONT_SIZE;
                break;
            case currentFontSize >= 48:
                updatedFontSize -= 12;
                break;
            case currentFontSize >= 24:
                updatedFontSize -= 4;
                break;
            case currentFontSize >= 14:
                updatedFontSize -= 2;
                break;
            case currentFontSize >= 9:
                updatedFontSize -= 1;
                break;
            default:
                updatedFontSize = LexicalConstants.MIN_ALLOWED_FONT_SIZE;
                break;
            }
            break;
    
        case LexicalConstants.UpdateFontSizeType.increment:
            switch (true) {
            case currentFontSize < LexicalConstants.MIN_ALLOWED_FONT_SIZE:
                updatedFontSize = LexicalConstants.MIN_ALLOWED_FONT_SIZE;
                break;
            case currentFontSize < 12:
                updatedFontSize += 1;
                break;
            case currentFontSize < 20:
                updatedFontSize += 2;
                break;
            case currentFontSize < 36:
                updatedFontSize += 4;
                break;
            case currentFontSize <= 60:
                updatedFontSize += 12;
                break;
            default:
                updatedFontSize = LexicalConstants.MAX_ALLOWED_FONT_SIZE;
                break;
            }
            break;
    
        default:
            break;
    }
    return updatedFontSize;
};

LexicalUtils.calculateNextFontSize = calculateNextFontSize;

const updateFontSizeInSelection = (editor, newFontSize, updateType) => {
    const getNextFontSize = (prevFontSize) => {
        if (!prevFontSize) {
            prevFontSize = `${LexicalConstants.DEFAULT_FONT_SIZE}px`;
        }
        prevFontSize = prevFontSize.slice(0, -2);
        const nextFontSize = calculateNextFontSize(
            Number(prevFontSize),
            updateType,
        );
        return `${nextFontSize}px`;
    };
  
    editor.update(() => {
        if (editor.isEditable()) {
            const selection = $getSelection();
            if (selection !== null) {
                $patchStyleText(selection, {
                    'font-size': newFontSize || getNextFontSize,
                });
            }
        }
    });
};

LexicalUtils.updateFontSizeInSelection = updateFontSizeInSelection;
  
LexicalUtils.updateFontSize = (editor, updateType, inputValue) => {
    if (inputValue !== '') {
        const nextFontSize = calculateNextFontSize(Number(inputValue), updateType);
        updateFontSizeInSelection(editor, String(nextFontSize) + 'px', null);
    } else {
        updateFontSizeInSelection(editor, null, updateType);
    }
};
  
export default LexicalUtils;