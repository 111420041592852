import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';

export default function LexicalOnBlurPlugin({ onBlur }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.registerCommand(
            BLUR_COMMAND,
            () => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                if (onBlur) {
                    onBlur(htmlString);
                }
            },
            COMMAND_PRIORITY_EDITOR
        );
    }, []);
  
    return null;
  };